import * as echarts from "echarts";

const componentChart = () => {
    const thgemissionen = document.getElementById("thgemissionen");
    if (thgemissionen) {
        var echartTHGEmissionen = echarts.init(thgemissionen);
        echartTHGEmissionen.setOption({
            title: {
                text: 'in t',
                right: '3%',
                top: '20%',
                textStyle: {
                    color: '#002d67',
                    fontFamily: 'GenosGFG',
                    fontSize: 12,
                    fontWeight: 'normal',
                }
            },
            legend: {},
            tooltip: {
                trigger: "item",
            },
            grid: {
                left: "0",
                right: "5%",
                bottom: "0",
                containLabel: true,
            },
            xAxis: {
                type: "value",
                splitLine: {
                    lineStyle: {
                        type: 'dashed',
                        color: '#002d67'
                    }
                },
                // name: 'in t',
                // nameTextStyle: {
                //     color: '#002d67'
                // },
                axisLabel: {
                    color: '#002d67'
                }
            },
            yAxis: {
                type: "category",
                data: ["2021"],
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                splitLine: {
                    lineStyle: {
                        type: 'dashed',
                        color: '#002d67'
                    }
                },
                axisLabel: {
                    color: '#002d67'
                }
            },
            series: [
                {
                    name: "Scope 1",
                    type: "bar",
                    stack: "total",
                    barWidth: "20",
                    label: {
                        show: true,
                    },
                    labelLine: {
                        show: true,
                        lineStyle: {
                            color: "red",
                            width: "100%",
                        },
                    },
                    emphasis: {
                        focus: "series",
                    },
                    data: [604],
                    color: "#aecb06",
                },
                {
                    name: "Scope 2",
                    type: "bar",
                    stack: "total",
                    label: {
                        show: true,
                    },
                    emphasis: {
                        focus: "series",
                    },
                    data: [130],
                    color: "#0066b3",
                },
                {
                    name: "Scope 3",
                    type: "bar",
                    stack: "total",
                    itemStyle: {
                        borderRadius: [0, 50, 50, 0],
                    },
                    label: {
                        show: true,
                    },
                    emphasis: {
                        focus: "series",
                    },
                    data: [1750],
                    color: "#002d67",
                }
            ],
        });

        window.onresize = function () {
            echartTHGEmissionen.resize();
        };
    }

    const energieverbrauch = document.getElementById("energieverbrauch");
    if (energieverbrauch) {
        var echartEnergieverbrauch = echarts.init(energieverbrauch);
        echartEnergieverbrauch.setOption({
            title: {
                text: 'in kWh',
                right: '0',
                top: '5%',
                textStyle: {
                    color: '#002d67',
                    fontFamily: 'GenosGFG',
                    fontSize: 12,
                    fontWeight: 'normal',
                }
            },
            tooltip: {
                trigger: "item",
            },
            grid: {
                left: '5%',
                right: '0',
                bottom: '5%',
                containLabel: true,
            },
            xAxis: {
                show: false,
                type: 'category',
                data: ['Energie (2021):', 'Gas (2021):', 'Fernwärme (2021):'],
            },
            yAxis: {
                type: 'value',
                // name: 'in kWh',
                splitLine: {
                    lineStyle: {
                        type: 'dashed',
                        color: '#002d67'
                    }
                },
                // nameTextStyle: {
                //     color: '#002d67',
                //     backgroundColor: 'red',
                // },
                axisLabel: {
                    color: '#002d67'
                },
            },
            series: [
                {
                    // name: 'sales',
                    type: 'bar',
                    itemStyle: {
                        borderRadius: [50, 50, 0, 0],
                    },
                    barWidth: "20px",
                    data: [
                        {
                            value: 2202094,
                            itemStyle: {
                                color: '#002d67'
                            }
                        },
                        {
                            value: 1959704,
                            itemStyle: {
                                color: '#aecb06'
                            }
                        },
                        {
                            value: 305219,
                            itemStyle: {
                                color: '#0066b3'
                            }
                        },

                    ],
                }
            ],
        });

        // window.onresize = function () {
        //     echartEnergieverbrauch.resize();
        // };
    }

    const abfall = document.getElementById("abfall");
    if (abfall) {
        var echartAbfall = echarts.init(abfall);
        echartAbfall.setOption({
            series: [
                {
                    type: 'gauge',
                    radius: '80%',
                    startAngle: 90,
                    endAngle: -270,
                    pointer: {
                        show: false
                    },
                    progress: {
                        show: true,
                        overlap: false,
                        roundCap: false,
                        clip: false,

                    },
                    axisLine: {
                        lineStyle: {
                            width: 20,
                            color: [[1, '#002d67']]
                        }
                    },
                    splitLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false,
                    },
                    data: [
                        {
                            value: '8.4',
                            name: 'Altpapier, Sonderabfall \n und Elektroschrott',
                            itemStyle: {
                                color: '#aecb06',
                            },
                            title: {
                                offsetCenter: ['0', '15%']
                            },
                            detail: {
                                valueAnimation: true,
                                offsetCenter: ['0', '-15%']
                            }
                        },
                    ],

                    title: {
                        textStyle: {
                            color: '#002d67',
                            fontFamily: 'GenosGFG',
                            fontSize: 16,
                            fontWeight: 'normal',
                            lineHeight: 20
                        }
                    },
                    detail: {
                        width: 50,
                        height: 60,
                        textStyle: {
                            color: '#002d67',
                            fontFamily: 'GenosGFG',
                            fontSize: 50,
                            fontWeight: 'normal'
                        },
                        formatter: '{value}%'
                    }
                }
            ]
        });

        // window.onresize = function () {
        //     echartEnergieverbrauch.resize();
        // };
    }

    if (abfall && energieverbrauch) {
        window.onresize = function () {
            echartAbfall.resize();
            echartEnergieverbrauch.resize();
        };
    }


    // const chart1 = document.getElementById('chart1')
    // if (chart1) {
    //   var myChart1 = echarts.init(chart1);
    //   myChart1.setOption({
    //     title: {
    //       text: 'Bar'
    //     },
    //     tooltip: {},
    //     xAxis: {
    //       data: ['shirt', 'cardigan', 'chiffon', 'pants', 'heels', 'socks']
    //     },
    //     yAxis: {},
    //     // grid: {
    //     // 	left: '5%'
    //     // } ,
    //     series: [
    //       {
    //         name: 'sales',
    //         type: 'bar',
    //         itemStyle: {
    //           borderRadius: [50, 50, 0, 0],
    //         },
    //         data: [5, 20, 36, 10, 10, 20],
    //         padding: 5
    //       }
    //     ]
    //   });
    // }

    // const chart2 = document.getElementById('chart2')
    // if (chart2) {
    //   var myChart2 = echarts.init(document.getElementById('chart2'));
    //   myChart2.setOption({
    //     title: {
    //       text: 'Bar Horozintal'
    //     },
    //     tooltip: {},
    //     xAxis: {},

    //     yAxis: {
    //       data: ['shirt', 'cardigan', 'chiffon', 'pants', 'heels', 'socks']
    //     },
    //     series: [
    //       {
    //         name: 'sales',
    //         type: 'bar',
    //         itemStyle: {
    //           borderRadius: [0, 50, 50, 0],
    //         },
    //         data: [
    //           5,
    //           {
    //             value: 20,
    //             itemStyle: {
    //               color: 'rgba(0, 150, 136, 1)'
    //             }
    //           },
    //           36,
    //           10,
    //           10,
    //           20
    //         ],
    //       }
    //     ],
    //   });
    // }

    // const chart3 = document.getElementById('chart3')
    // if (chart3) {
    //   var myChart3 = echarts.init(document.getElementById('chart3'));
    //   myChart3.setOption({
    //     title: {
    //       text: 'Bar Horozintal'
    //     },
    //     tooltip: {},
    //     xAxis: {
    //       type: 'category',
    //       data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    //     },
    //     yAxis: {
    //       type: 'value'
    //     },
    //     series: [
    //       {
    //         data: [150, 230, 224, 218, 135, 147, 260],
    //         type: 'line',
    //         color: '#f60'
    //       },
    //       {
    //         data: [200, 250, 200, 100, 150, 180, 200],
    //         type: 'line'
    //       },
    //     ]
    //   });
    // }

    // const chart4 = document.getElementById('chart4')
    // if (chart4) {
    //   var myChart4 = echarts.init(document.getElementById('chart4'));
    //   myChart4.setOption({
    //     tooltip: {
    //       trigger: 'item'
    //     },
    //     legend: {
    //       top: '5%',
    //       left: 'center'
    //     },
    //     series: [
    //       {
    //         name: 'Access From',
    //         type: 'pie',
    //         radius: ['50%', '70%'],
    //         avoidLabelOverlap: false,
    //         label: {
    //           show: false,
    //           position: 'center'
    //         },
    //         // emphasis: {
    //         // 	label: {
    //         // 		show: true,
    //         // 		fontSize: '40',
    //         // 		fontWeight: 'bold'
    //         // 	}
    //         // },
    //         labelLine: {
    //           show: false
    //         },
    //         data: [
    //           { value: 50, itemStyle: { color: 'rgba(0, 150, 136, 1)' }, name: 'Search Engine' },
    //           { value: 25, name: 'Direct' },
    //           { value: 25, name: 'Email' }
    //         ]
    //       }
    //     ]
    //   });
    // }
};

export default componentChart;
