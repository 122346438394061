import gsap from "gsap";

const siegelBar = () => {

	const capitalLogo = document.querySelector(".capital");
	if (capitalLogo) {
		document.addEventListener("scroll", () => {
			if (window.pageYOffset > 200) {
				capitalLogo.classList.add("hideSiegel");
			} else {
				capitalLogo.classList.remove("hideSiegel");
			}
		});
	}

	const handelsblattLogo = document.querySelector(".handelsblatt");
	if (handelsblattLogo) {
		document.addEventListener("scroll", () => {
			if (window.pageYOffset > 200) {
				handelsblattLogo.classList.add("hideSiegel");
			} else {
				handelsblattLogo.classList.remove("hideSiegel");
			}
		});
	}

	const stiftungsexperteLogo = document.querySelector(".meinstiftungsexperte");
	if (stiftungsexperteLogo) {
		document.addEventListener("scroll", () => {
			if (window.pageYOffset > 200) {
				stiftungsexperteLogo.classList.add("hideSiegel");
			} else {
				stiftungsexperteLogo.classList.remove("hideSiegel");
			}
		});
	}

};
export default siegelBar;
